.search-container {
  position: relative;
  top: -2rem;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
}
