/* CommonBanner.css */
.banner {
  background: rgba(0, 0, 0, 0.4) url("../images/hero.jpg") center center/cover;
  background-blend-mode: darken;
  background-size: cover;
  background-position: center;
  filter: contrast(2);
  width: 100%;
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
}

.banner-title {
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.1rem;
}

@media screen and (max-width: 768px) {
  .banner {
    height: 32vh;
  }

  .banner-title {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 576px) {
  .banner-title {
    font-size: 2rem;
  }
}

@media screen and (max-width: 480px) {
  .banner-title {
    font-size: 1.8rem;
  }
}
