.services-container {
  /* background:#ffbf0070 url('../images/city-1117433.png') top  center/cover; */
  /* background:rgba(0, 0, 0, 0.4) url('../images/daniel-zacatenco-sx0cAH8TEYk-unsplash.jpg') center  center/cover; */
  background: rgba(0, 0, 0, 0.4) url("../images/services-we-offer.jpg") center center/cover;
  /* background: rgba(0, 0, 0, 0.5) url('../images/servicesbg.jpg') center center/cover; */
  background-blend-mode: darken;
  /* background-color: #ffbf005b; */
}

.services-card {
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5%;
  cursor: pointer;
}
.fontawesome-style {
  text-align: center;
  color: #ffc418;
}

.serv-card-icon {
  height: 4rem;
  width: 4rem;
  background-color: #ffc51846;
  border-radius: 50%;
  margin: auto;
}

.card_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  font-family: "Quicksand", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.25s;
  border-radius: 5%;
}

.card_overlay--primary {
  background: #ffc518;
}

.card_overlay > * {
  transform: translateY(20px);
  transition: transform 0.25s;
}

.card_overlay:hover {
  opacity: 1;
}

.card_overlay:hover > * {
  transform: translateY(0);
}

.overlay_title {
  font-size: 1.4rem;
  font-weight: 700;
  color: black;
}

@media screen and (min-width: 280px) {
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
