.navbar {
  top: 0 !important;
  z-index: 100 !important;
  background-color: transparent !important;
}

.navbar.activate {
  /* background-color: #212529!important ; */
  background-color: black !important ;
}

.nav-item {
  font-size: 1.2rem;
  font-weight: 400;
}

.navbar-brand {
  width: 15% !important;
}
.rr-logo {
  width: 100%;
}

.navbar-toggle-icon {
  color: white !important;
}

.navbar-toggler {
  border-color: none !important;
  border: unset !important;
}

.navbar-toggler:focus {
  outline: unset !important;
  border: unset !important;
  box-shadow: none !important;
  border-color: none !important;
}

@media screen and (max-width: 1024px) {
  .navbar-brand {
    width: 30% !important;
  }
  /* .rr-logo {
    width: 60%;
  } */
}

@media (min-width: 992px) {
  .offcanvas {
    visibility: visible;
    position: relative;
    background: none;
    border: none;
    justify-content: end;
    background-color: #212529;
  }
}

@media (max-width: 992px) {
  .offcanvas {
    width: 300px !important;
    /* background-color: #212529 ; */
    background-color: black;
  }

  .offcanvas-start-lg {
    top: 0;
    left: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    transform: translateX(-100%);
  }
}

.navbar-nav li a {
  color: white !important;
}
