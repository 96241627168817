.link-to-search {
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.link-to-search:hover {
  color: #ffc418;
}

@media screen and (max-width: 768px) {
  .link-to-search {
    font-size: 1.2rem;
  }
}
