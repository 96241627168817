.video-container {
  overflow: hidden;
  position: relative;
  width: 85%;
  margin: auto;
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: "";
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1199px) {
  .property-map {
    height: 45vh;
  }
}

@media screen and (max-width: 1024px) {
  .video-container {
    overflow: hidden;
    position: relative;
    width: 100%;
    margin: auto;
  }
}
