/* .contact-text{
    position: relative;
    visibility: hidden;
    transform:translate(0px,20px);
    transition: all 0.3s ease-in-out ;
}
.contact-fig{
    position: relative;
    transition: all 0.3s ease-in-out ;
    transition-timing-function: ease-in-out;
    
}

@keyframes mymove {
    from {
        top: -40px;
    }
    to {
        top: 0px;
    }
  }


.contact-fig:hover .contact-text{
    
    visibility: visible;
    
}

.contact-fig:hover{

    transform:translate(0px,-40px);
    
    animation-iteration-count: 1;   
} */

.contact-text:hover {
  text-decoration: underline;
  /* border-bottom: 2px solid #e6d57f; */
}

@media screen and (max-width: 991px) {
  .row2 {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 767px) {
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
