.footer-items {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

footer {
  text-align: center;
  margin: 1rem 0;
  margin-top: 1.5rem;
}

.f-navlinks {
  text-decoration: none;
  color: grey;
}
.f-navlinks:hover {
  color: #d4b470;
}
.social-media-links {
  color: grey;
}
.social-media-links:hover {
  color: #d4b470;
}

.footer-links:hover {
  text-decoration: underline;
  /* border-bottom: 2px solid #e6d57f; */
}

@media screen and (max-width: 767px) {
  .col-mdf-6 {
    flex: 0 0 auto !important;
    width: 100% !important;
  }
}
