/* Card.css */
.property-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.property-card:hover {
  transform: translateY(-5px);
}

.card-link {
  text-decoration: none;
  color: inherit;
}

.card-link:hover {
  color: grey;
}

.iframe-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  overflow: hidden;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.card-body {
  padding: 1rem;
}

.card-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-align: left;
}

.property-info {
  display: flex;
  align-items: left;
  flex-direction: column;
  margin-bottom: 0.5rem;
}

.property-location {
  flex: 1;
  display: flex;
  align-items: center;
}

.property-location i {
  margin-right: 0.5rem;
}

.property-details {
  display: flex;
  align-items: center;
}

.property-details i {
  margin-right: 0.25rem;
}

.price,
.sale-type {
  text-align: left;
  font-size: 1rem;
  margin-top: 0.5rem;
  color: #ffc107; /* Adjust color as needed */
}

.price {
  color: black;
}
