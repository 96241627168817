.serv-header {
  font-size: 3.5rem;
  font-weight: 600;
}

.serv-para {
  font-size: 1.6rem;
}

@media screen and (max-width: 1162px) {
  .serv-header {
    font-size: 3.2rem;
  }

  .serv-para {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 1000px) {
  .serv-header {
    font-size: 2.8rem;
  }

  .serv-para {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 768px) {
  .serv-header {
    font-size: 2.5rem;
  }

  .serv-para {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 559px) {
  .serv-header {
    font-size: 2.2rem;
  }

  .serv-para {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 498px) {
  .serv-header {
    font-size: 2rem;
  }

  .serv-para {
    font-size: 1rem;
  }
}
