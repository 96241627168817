/* HeroSection.css */
.hero-section {
  background: rgba(0, 0, 0, 0.4) url("../images/hero.jpg") center center/cover;
  background-blend-mode: darken;
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
}

.hero-content {
  max-width: 800px;
  padding: 0 20px;
}

.hero-title {
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 20px;
}

.accent-color {
  color: #d0a24c;
}

.hero-para {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 30px;
}

.hero-btn {
  background-color: #d0a24c;
  color: #fff;
  font-size: 1.2rem;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.hero-btn:hover {
  background-color: #ffc107;
}

@media screen and (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 576px) {
  .hero-title {
    font-size: 2rem;
  }
  .hero-para {
    font-size: 1rem;
  }
  .hero-btn {
    font-size: 1rem;
  }
}
